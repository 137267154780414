import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { create } from 'playable/dist/src/core/player-factory';
import VIDEO_EVENTS from 'playable/dist/src/constants/events/video';
import ENGINE_STATES from 'playable/dist/src/constants/engine-state';
import UI_EVENTS from 'playable/dist/src/constants/events/ui';
import type { IPlayerConfig } from 'playable/dist/statics/core/config';
import type { PreloadType } from 'playable/dist/statics/modules/playback-engine/types';
import Image from '../../../../Image/viewer/Image';
import { TestIds } from '../../constants';

import { st, classes } from '../../style/VideoPlayer.st.css';
import { useChangedEffect } from '../../../../../providers/useChangedEffect';
import { useDidMount } from '../../../../../providers/useDidMount';
import type {
  IPlayer,
  IPlayerHandles,
  IPlayablePlayerInstanceFixed as IPlayerInstanceFixed,
  IPlayerProps,
} from '../players.types';

import { useAsyncRef } from '../../../../../providers/useAsyncRef';
import PlayableCover from './PlayableCover';

const usePlayer = (
  config: IPlayerConfig,
): [() => Promise<IPlayerInstanceFixed>, () => IPlayerInstanceFixed | void] => {
  const [waitForPlayer, getPlayer, setPlayer] =
    useAsyncRef<IPlayerInstanceFixed>();

  useDidMount(() => {
    setPlayer(create(config) as IPlayerInstanceFixed);
    return () => {
      const player = getPlayer() as IPlayerInstanceFixed;
      player.destroy?.();
    };
  });

  return [waitForPlayer, getPlayer];
};

const showUI = (player: IPlayerInstanceFixed): void => {
  player.showMainUI!();
  player.getElement!().tabIndex = 0;
};
const hideUI = (player: IPlayerInstanceFixed): void => {
  player.hideMainUI!();
  player.getElement!().tabIndex = -1;
};

const useChangedPropsEffects = (
  {
    src,
    playing,
    muted,
    volume,
    title,
    preload,
    showTitle,
    controls,
  }: Partial<IPlayerProps>,
  waitForPlayer: () => Promise<IPlayerInstanceFixed>,
  firstPlayStarted: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
): void => {
  useChangedEffect(src, async () => {
    firstPlayStarted.current = false;
    firstPlayEnded.current = false;

    (await waitForPlayer()).setSrc!(src!);
  });

  useChangedEffect(playing, async () =>
    playing
      ? (await waitForPlayer()).play!()
      : (await waitForPlayer()).pause!(),
  );

  useChangedEffect(muted, async () =>
    muted ? (await waitForPlayer()).mute!() : (await waitForPlayer()).unmute!(),
  );

  useChangedEffect(volume, async () =>
    (await waitForPlayer()).setVolume!(volume!),
  );

  useChangedEffect(title, async () => (await waitForPlayer()).setTitle!(title));

  useChangedEffect(preload, async () =>
    (await waitForPlayer()).setPreload!(preload!),
  );

  useChangedEffect(showTitle, async () =>
    showTitle
      ? (await waitForPlayer()).showTitle!()
      : (await waitForPlayer()).hideTitle!(),
  );

  useChangedEffect(controls, async () =>
    controls ? showUI(await waitForPlayer()) : hideUI(await waitForPlayer()),
  );
};

type AdapterEvents = {
  onFocus?: () => void;
  onBlur?: () => void;
};

const subscribeToPlayerEvents = (
  player: IPlayerInstanceFixed,
  {
    controls,
    onReady,
    onDuration,
    onFirstPlay,
    onPlay,
    onPause,
    onFirstEnded,
    onEnded,
    onProgress,
    onFocus,
    onBlur,
  }: Partial<IPlayerProps & AdapterEvents>,
  setHasBeenPlayed: React.Dispatch<React.SetStateAction<boolean>>,
  isPlayingNow: React.MutableRefObject<boolean>,
  firstPlayStarted: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
): void => {
  player.on!(ENGINE_STATES.PLAY_REQUESTED, () => {
    if (controls) {
      showUI(player);
    }
    setHasBeenPlayed(true);
  });

  player.on!(ENGINE_STATES.METADATA_LOADED, () => {
    onReady?.();
    onDuration?.(player.getDuration!());
  });

  player.on!(UI_EVENTS.FOCUS_ENTER_ON_PLAYER, () => {
    onFocus?.();
  });

  player.on!(UI_EVENTS.FOCUS_LEAVE_ON_PLAYER, () => {
    onBlur?.();
  });

  player.on!(ENGINE_STATES.PLAYING, () => {
    setHasBeenPlayed(true);

    if (!firstPlayStarted.current) {
      firstPlayStarted.current = true;
      onFirstPlay?.();
    }

    isPlayingNow.current = true;
    onPlay?.();
  });

  player.on!(ENGINE_STATES.PAUSED, () => {
    isPlayingNow.current = false;
    onPause?.();
  });

  player.on!(ENGINE_STATES.ENDED, () => {
    setHasBeenPlayed(false);

    if (!firstPlayEnded.current) {
      firstPlayEnded.current = true;
      onFirstEnded?.();
    }

    isPlayingNow.current = false;
    onEnded?.();
  });

  player.on!(VIDEO_EVENTS.CURRENT_TIME_UPDATED, (currentTime: number) => {
    onProgress?.(currentTime);
  });
};

const getHandles = (
  waitForPlayer: () => Promise<IPlayerInstanceFixed>,
  getPlayer: () => IPlayerInstanceFixed | void,
  isPlayingNow: React.MutableRefObject<boolean>,
): IPlayerHandles => {
  const handles: IPlayerHandles = {
    play: () =>
      new Promise(async resolve => {
        const player = await waitForPlayer();
        player.once!(ENGINE_STATES.PLAYING, resolve);
        player.play!();
      }),
    pause: () =>
      new Promise(async resolve => {
        const player = await waitForPlayer();
        player.once!(ENGINE_STATES.PAUSED, resolve);
        player.pause!();
      }),
    togglePlay: () => (isPlayingNow.current ? handles.pause() : handles.play()),
    stop: async () => (await waitForPlayer()).reset!(),
    getDuration: async () => {
      const player = getPlayer();
      return player ? player.getDuration!() || 0 : 0;
    },
    getCurrentTime: async () => {
      const player = getPlayer();
      return player ? player.getCurrentTime!() || 0 : 0;
    },
    seekTo: async amount => (await waitForPlayer()).seekTo!(amount),
    setVolume: async fraction => (await waitForPlayer()).setVolume!(fraction),
    getVolume: async () => {
      const player = getPlayer();
      return player ? player.getVolume!() || 0 : 0;
    },
    isMuted: async () => {
      const player = getPlayer();
      return player ? player.isMuted! : true;
    },
    isPlaying: () => isPlayingNow.current,
    mute: async () => (await waitForPlayer()).mute!(),
    unMute: async () => (await waitForPlayer()).unmute!(),
  };

  return handles;
};

const noop = () => void 0;
const Player: IPlayer = (props, ref) => {
  const {
    id,
    src,
    showTitle,
    title,
    poster,
    hideOverlay,
    playing,
    muted,
    preload = 'none' as PreloadType,
    animatePoster,
    controls,
    loop,
    texts = {},
    hideMainUI = false,
    description,
    translations,
    isMobileView,
    onReady = noop,
    onDuration = noop,
    onProgress = noop,
    onPlay = noop,
    onPause = noop,
    onEnded = noop,
    onFirstPlay = noop,
    onFirstEnded = noop,
    onInit = noop,
  } = props;

  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [waitForPlayer, getPlayer] = usePlayer({
    src,
    autoplay: Boolean(playing),
    playsinline: true,
    muted,
    fillAllSpace: true,
    title,
    preload: (!poster ? 'metadata' : preload) as PreloadType,
    loop,
    texts,
    hideOverlay: true,
    hideMainUI,
    preventContextMenu: true,
  });

  const [focused, setFocused] = React.useState<boolean>(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const [hasBeenPlayed, setHasBeenPlayed] = React.useState<boolean>(false);
  const isPlayingNow = React.useRef<boolean>(false);
  const firstPlayStarted = React.useRef<boolean>(false);
  const firstPlayEnded = React.useRef<boolean>(false);

  const [posterAnimated, runPosterAnimation] = React.useState<boolean>(false);
  const [animationFinished, setAnimationFinished] =
    React.useState<boolean>(false);

  useDidMount(() => {
    waitForPlayer()
      .then(player => {
        hideUI(player);

        if (isMobileView) {
          const element = player.getElement!();
          const videoElement = element.querySelector('video');
          element.addEventListener('click', () => {
            if (videoElement?.paused) {
              player.play!();
            } else {
              player.pause!();
            }
          });
        }

        if (!showTitle) {
          player.hideTitle!();
        }

        player.attachToElement!(containerRef.current!);

        subscribeToPlayerEvents(
          player,
          {
            controls,
            onReady,
            onDuration,
            onFirstPlay,
            onPlay,
            onPause,
            onFirstEnded,
            onEnded,
            onProgress,
            onFocus,
            onBlur,
          },
          setHasBeenPlayed,
          isPlayingNow,
          firstPlayStarted,
          firstPlayEnded,
        );

        onInit(player, 'playable');
      })
      .catch(e => {
        // TODO - handle errors properly
        throw e;
      });
  });

  useChangedPropsEffects(
    { src, playing, muted, title, loop, preload, showTitle, controls },
    waitForPlayer,
    firstPlayStarted,
    firstPlayEnded,
  );

  React.useImperativeHandle(ref, () =>
    getHandles(waitForPlayer, getPlayer, isPlayingNow),
  );

  const onPlayClick = React.useCallback(async () => {
    (await waitForPlayer()).play!();
    runPosterAnimation(true);
  }, [waitForPlayer]);

  return (
    <React.Fragment>
      <div
        ref={containerRef}
        data-player-name="Playable"
        data-testid={TestIds.playable}
        className={classes.playerContainer}
      />

      {poster && animatePoster === 'fade' && (
        <CSSTransition
          in={posterAnimated}
          timeout={1600}
          classNames={{
            enter: classes.fadeEnter,
            enterActive: classes.fadeEnterActive,
          }}
          onEntered={() => {
            setAnimationFinished(true);
          }}
        >
          <div className={classes.secondPoster}>
            {!animationFinished && (
              <Image
                id={`poster_${id}`}
                {...poster}
                containerId={id}
                displayMode="fill"
                alt={description || ''}
                name=""
              />
            )}
          </div>
        </CSSTransition>
      )}
      <PlayableCover
        id={id}
        showTitle={showTitle}
        title={title}
        poster={poster}
        hideOverlay={hideOverlay}
        playing={playing}
        description={description}
        hasBeenPlayed={hasBeenPlayed}
        onPlay={onPlayClick}
        translations={translations}
        className={st(classes.cover, {
          isMobileView,
          playerFocused: focused,
        })}
      />
    </React.Fragment>
  );
};

export default React.forwardRef(Player);
